.custom-wrapper {
	width: 100% !important;
	display: block !important;
	border: "1px solid #969696";
}

.custom-editor {
	border: 1px solid #f1f1f1 !important;
	padding: "1rem";
	border-radius: 2px !important;
	height: "40rem";
}
