.container {
	.header-div {
		font-size: 1.2em;
		justify-content: center;
		color: white;
	}

	.email-entry-button {
		width: 80%;
		height: 5vh;
		line-height: 5vh;
		display: inline-flex;
		justify-content: left;
		.email-div {
			width: 50%;
			height: 5vh;
			line-height: 5vh;
			display: inline-flex;
			justify-content: center;
			.email-label {
				width: 20%;
				line-height: 5vh;
				font-size: 16px;
				height: 100%;
				.error {
					color: red;
				}
			}
			.email-input {
				width: 80%;
				height: 5vh;
				line-height: 5vh;
				font-size: 16px;
				height: 100%;
			}
		}
		.entry-button {
			width: 20%;
			height: 5vh;
			line-height: 5vh;
		}
	}

	.user-name-div {
		background-color: orange;
		width: 80%;
		height: 5vh;
		line-height: 5vh;
		display: inline-flex;
		justify-content: left;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.preview-div {
		padding-top: 0.5vh;
		padding-bottom: 0.5vh;
		justify-content: right;
		width: 100%;
		height: 6vh;
		line-height: 5vh;
		display: inline-flex;
		background-color: white;
		color: black;
		.email-preview-button {
			width: 80%;
			height: 5vh;
			line-height: 5vh;
			display: inline-flex;
			justify-content: left;
			.email-div {
				width: 50%;
				height: 5vh;
				line-height: 5vh;
				display: inline-flex;
				justify-content: center;
				.email-label {
					width: 20%;
					line-height: 5vh;
					font-size: 16px;
					height: 100%;
					.error {
						color: red;
					}
				}
				.email-input {
					width: 80%;
					height: 5vh;
					line-height: 5vh;
					font-size: 16px;
					height: 100%;
				}
			}
			.preview-button {
				width: 20%;
				height: 5vh;
				line-height: 5vh;
			}
		}
		.preview-checkbox {
			width: 20%;
			height: 5vh;
			display: inline-flex;
			justify-content: right;

			.preview-checkbox-label {
				line-height: 5vh;
				// background-color: lightgoldenrodyellow
			}

			input.checkbox-input {
				width: 5vw;
				height: 5vh;
				font-size: 16px;
			}
			.checkbox-input {
				height: 5vh;
				line-height: 5vh;
				margin: 0px;
				padding: 0px;
			}
		}
	}

	label {
		font-weight: bold;
	}

	.commodity-name-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.commodity-name {
			width: 40%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				width: 90%;
			}
		}
		.commodity-text-hints {
			width: 50%;
			display: inline-flex;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				width: 90%;
			}
		}
	}

	.invoice-number-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.invoice-number {
			width: 40%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				width: 90%;
			}
		}
		.invoice-number-text-hints {
			width: 50%;
			display: inline-flex;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				width: 90%;
			}
		}
	}

	.shipment-carrier-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.shipment-carrier {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.shipment-carrier-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.shipment-vessel-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.shipment-vessel {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.shipment-vessel-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.shipment-voyage-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.shipment-voyage {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.shipment-voyage-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.cargo-value-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.cargo-value {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.cargo-value-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.cargo-quantity-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.cargo-quantity {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.cargo-quantity-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.cargo-weight-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.cargo-weight {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.cargo-weight-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.cargo-number-of-containers-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.cargo-number-of-containers {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.cargo-number-of-containers-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.insured-value-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.insured-vallue {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.insured-vallue-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.beneficiary-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.beneficiary {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.beneficiary-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.port-of-discharge-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.port-of-discharge {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.port-of-discharge-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.port-of-loading-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.port-of-loading {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.port-of-loading-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.ETD-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.ETD {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.ETD-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.ETA-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.ETA {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.ETA-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.currency-of-claim-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.currency-of-claim {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.currency-of-claim-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.location-of-claim-payment-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.location-of-claim-payment {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.location-of-claim-payment-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.payment-method-div {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.payment-method {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.payment-method-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.policy-choice-checkbox {
		background-color: white;
		display: inline-flex;
		width: 100%;
		.policy-choice {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.policy-choice-text-hints {
			width: 50%;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}

	.submit-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.submit-div:disabled {
		background-color: grey;
	}

	.update-button-div {
		line-height: 15px;
		text-align: center;
		width: 10%;
		background-color: green;
		height: 15px;
		margin: auto;
		vertical-align: center !important;
		.btn-primary {
			margin: auto;
			background-color: yellow;
			font-size: 12px;
			// line-height: 15px;
			// height: 15px;
			text-align: center;
			vertical-align: center !important;
		}
	}
}
