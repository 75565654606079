.insurance-onboard-container {
	//background-color: white;
	.header-div {
		font-size: 1.2em;
		justify-content: center;
		color: white;
		// background-color: white;
	}

	label {
		font-weight: bold;
	}

	.company-name-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.company-address-div {
		background-color: white;
		.company-address-city-div {
			//background-color: yellow;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.company-address-streetline1-div {
			//background-color: blue;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.company-address-streetline2-div {
			//background-color: blueviolet;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.company-address-streetline3-div {
			//background-color: peru;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}

		.company-address-housenumber-div {
			//background-color: lightcoral;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
		.company-address-zipcode-div {
			//background-color: lightgreen;
			.error {
				font-size: 10px;
				height: 3vh;
				color: red;
				//justify-content: start;
				width: 90%;
				//text-align: right;
			}
		}
	}
	.company-phone-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.company-size-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.company-website-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.company-registration-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.company-revenue-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}
}
