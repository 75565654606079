.application-form-container {
	background-color: white;
	width: 80%;
	padding: 2cm;
	margin-left: auto;
	margin-right: auto;
	.p-center {
		text-align: center;
	}

	.div-center {
		text-align: center;
		// justify-content: center;
	}

	table,
	th,
	td {
		border-color: black;
		border-style: solid;
		border-width: 5px;
	}

	.application-page-container {
		// background-color: red;
		width: 100%;
		.p-center {
			text-align: center;
		}

		.div-center {
			text-align: center;
			// justify-content: center;
		}

		table,
		th,
		td {
			// border: 1px solid black;
			border-color: black;
			border-style: solid;
			border-width: 5px;
		}
	}
}
