.pin-entry-div {
	width: 100%;
	background-color: lightgoldenrodyellow; //##f5f5f5;

	// height: 100%;
	.share-status-message {
		width: 100%;
		padding-top: 1vh;
		padding-left: 1vh;
		padding-bottom: 1vh;
		padding-right: 5vh;
		text-align: center;
		font-size: 1.4rem;
		vertical-align: middle;
		.status-text-span {
			vertical-align: middle;
			color: #009966;
		}
		.status-text-green {
			color: #009966;
		}
		.status-text-yellow {
			color: #ffff00;
		}
		.status-text-red {
			color: #ff0000;
		}
	}
	.pin-entry-message {
		width: 100%;
		padding-top: 1vh;
		padding-left: 5vw;
		padding-right: 5vw;
		// display: flex;
		text-align: center;
		font-size: 1.4rem;
		vertical-align: middle;
		p {
			margin: 0;
		}
	}
	.pin-entry-content {
		width: 100%;
		padding-top: 2vh;
		padding-bottom: 2vh;
		padding-left: 5vw;
		padding-right: 5vw;
		display: inline-flex;
		justify-content: center;
		text-align: center;
		font-size: 1.4rem;
		vertical-align: middle;
		.pin-input {
			font-size: 1.4rem;
			width: 8.4rem; //6 x font-size above
			text-align: justify;
			text-justify: inter-character; //evenly space out the text of 6 digits
		}
		.pin-submit {
			border-radius: 1rem;
			margin-left: 5rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.pin-request {
			border-radius: 1rem;
			margin-left: 5rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	.pin-entry-confirmation {
		width: 100%;
		padding-top: 2vh;
		padding-left: 5vw;
		padding-right: 5vw;
		text-align: center;
		font-size: 1.4rem;
		vertical-align: middle;
	}
}
