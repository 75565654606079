.container {
	// background-color: white;
	.header-div {
		font-size: 1.2em;
		justify-content: center;
		color: white;
		// background-color: white;
	}

	label {
		font-weight: bold;
	}

	.name-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.email-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.message-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}
}
