.newsletter-container {
	// background-color: white;
	.header-div {
		font-size: 1.2em;
		justify-content: center;
		color: white;
		// background-color: white;
	}

	label {
		font-weight: bold;
	}

	.name-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.email-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.language-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.submit-button {
		transition: background-color 0.2s ease-in-out;
		background: #009966;
		border-radius: 40px;
		border: 0;
		color: white;
		cursor: pointer;
		display: inline-block;
		padding: 0.8em 2em;
		text-align: center;
		text-decoration: none;
		font-size: 1.4em;
		font-weight: 600;
		min-width: 10em;
	}

	.message-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}
}
