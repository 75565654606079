.container {
	.header-div {
		font-size: 1.2em;
		justify-content: center;
		color: white;
	}
	.preview-div {
		padding-top: 0.5vh;
		padding-bottom: 0.5vh;
		justify-content: right;
		width: 100%;
		height: 6vh;
		line-height: 5vh;
		display: inline-flex;
		background-color: white;
		color: black;
		.email-preview-button {
			width: 80%;
			height: 5vh;
			line-height: 5vh;
			display: inline-flex;
			justify-content: left;
			.email-div {
				width: 50%;
				height: 5vh;
				line-height: 5vh;
				display: inline-flex;
				justify-content: center;
				.email-label {
					width: 20%;
					line-height: 5vh;
					font-size: 16px;
					height: 100%;
					.error {
						color: red;
					}
				}
				.email-input {
					width: 80%;
					height: 5vh;
					line-height: 5vh;
					font-size: 16px;
					height: 100%;
				}
			}
			.preview-button {
				width: 20%;
				height: 5vh;
				line-height: 5vh;
			}
		}
		.preview-checkbox {
			width: 20%;
			height: 5vh;
			display: inline-flex;
			justify-content: right;

			.preview-checkbox-label {
				line-height: 5vh;
				// background-color: lightgoldenrodyellow
			}

			input.checkbox-input {
				width: 5vw;
				height: 5vh;
				font-size: 16px;
			}
			.checkbox-input {
				height: 5vh;
				line-height: 5vh;
				margin: 0px;
				padding: 0px;
			}
		}
	}

	label {
		font-weight: bold;
	}

	.pin-div {
		background-color: white;
		width: 100%;
	}
	.title-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.text-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.language-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.email-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.submit-div {
		background-color: white;
		.error {
			font-size: 10px;
			height: 3vh;
			color: red;
			//justify-content: start;
			width: 90%;
			//text-align: right;
		}
	}

	.submit-div:disabled {
		background-color: grey;
	}
}
