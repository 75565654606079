.FilesUploader {
	text-align: center;
	background-color: white;
	color: black;
	border-width: 2px;
	border-style: solid;
	width: 100%;

	.filename-and-progress {
		width: 100%;
		display: inline-block;

		.filename-and-delete {
			height: 15px;
			width: 100%;
			line-height: 15px;
			font-size: 12px;
			display: inline-flex;
			.filename {
				width: 70%;
				background-color: yellow;
				text-align: left;
				vertical-align: center;
			}
			.delete-file {
				text-align: right;
				width: 30%;
				background-color: red;
				color: white;
				vertical-align: center;
				.btn-primary {
					background-color: blue;
					font-size: 12px;
					line-height: 15px;
					height: 15px;
				}
			}
		}
		.progress-bar {
			height: 15px;
			background-color: green;
			line-height: 15px;
			text-align: center;
			color: white;
			vertical-align: center;
			font-size: 12px;
		}
	}

	.file-selection-and-upload {
		width: 100%;
		height: 15px;
		background-color: crimson;
		display: inline-flex;
		//upload choose file input
		.file-selection {
			text-align: left;
			width: 60%;
			height: 15px;
			background-color: yellow;
			text-align: left;
			vertical-align: center !important;
			.btn-primary {
				background-color: green;
				font-size: 12px;
				line-height: 15px;
				height: 15px;
				vertical-align: center !important;
				display: inline-flex;
				width: 100%;
				.input-file {
					width: 50%;
					vertical-align: center;
					background-color: white;
					font-size: 12px;
					line-height: 15px;
					height: 15px;
				}
			}
		}

		//upload button
		.file-upload {
			line-height: 15px;
			text-align: center;
			width: 40%;
			background-color: green;
			height: 15px;
			vertical-align: center !important;
			.btn-primary {
				background-color: yellow;
				font-size: 12px;
				line-height: 15px;
				height: 15px;
				vertical-align: center !important;
			}
		}
	}

	.alert {
		color: red;
	}
	.alert-secondary {
		color: pink;
	}

	//card showing list of files uploaded

	.card {
		width: 100%;
		background-color: yellow;
		.card-header {
			background-color: blue;
		}
		.list-group {
			background-color: plum;
			.list-group-item {
				background-color: bisque;
			}
		}
		.list-group-flush {
			background-color: coral;
		}
	}
}
